// import logo from './logo.svg';
import PassGenApp from "./pages/home.js";

function App() {
  return (
    <div className="App">
      <PassGenApp />
    </div>
  );
}

export default App;
